<template>
  <div>
    <div v-if="!submited && step < 7">
      <div v-if="step != 0 && step < 5">
        <Stepper :defaultstep="step" />
      </div>
      <h4
        v-if="step == 1 || step == 2 || step == 3 || step == 4"
        class="text-decoration- orange--text text-center p-5 form_headin mb-4"
      >
        Stap {{ step }}
        <hr class="orange--text" />
      </h4>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <v-form ref="myForm" @submit.prevent="handleSubmit(onSubmit)">
          <!-- start quiz -->
          <div v-if="step == 0" class="text-center my-4">
            <h2 class="pb-5 fs--16 fw--700">
              Doe de woningcheck en controleer of jouw woning in aanmerking
              komt.
            </h2>
            <div class="checks py-1">
              <img :src="check" class="mx-3" />
              <b class="fs--15 fw--600">Zijn we actief in jouw regio?</b>
            </div>
            <div class="checks py-1">
              <img :src="check" class="mx-3" />
              <b class="fs--15 fw--600">Dakcheck: is jouw dak geschikt?</b>
            </div>
            <div class="checks py-1">
              <img :src="check" class="mx-3" />
              <b class="fs--15 fw--600">Gratis berekening</b>
            </div>
          </div>
          <!-- step 1 -->
          <div v-if="step == 1">
            <v-row class="px-1 pt-1">
              <div class="col-12">
                <label for="rentalOrOwner" class="fs--15 font-weight-bold"
                  >Heb je een huur- of koopwoning?</label
                >
                <div class="row mt-0">
                  <v-col class="pl-2">
                    <label class="btn btn-lg text-dark cursor-pointer"
                      ><input
                        required
                        value="3280"
                        type="radio"
                        name="toggle"
                        v-model="temp_form.koop"
                      /><span class="btnDiv">Huurwoning</span></label
                    ></v-col
                  >
                  <v-col class="pr-2">
                    <label class="btn btn-lg text-dark cursor-pointer"
                      ><input
                        required
                        value="3277"
                        type="radio"
                        name="toggle"
                        v-model="temp_form.koop"
                      /><span class="btnDiv">Koopwoning</span></label
                    ></v-col
                  >
                </div>
              </div>
            </v-row>
          </div>
          <!-- step 2 -->
          <div v-else-if="step == 2">
            <label for="rentalOrOwner" class="fs--15 font-weight-bold"
              >Zijn we actief in jouw regio?</label
            >
            <v-row class="mt-1">
              <div class="col-12 col-sm-7 pr-3 pr-sm-2">
                <validation-provider
                  v-slot="{ errors }"
                  name="postcode"
                  ref="postcode"
                  :rules="{
                    required: true,
                    regex: /^([0-9]{4}[a-zA-Z]{2})$/,
                    ErrorResponse: {
                      target: getErrorResponses.zip,
                    },
                  }"
                >
                  <v-text-field
                    v-model="form.zip"
                    @input="handleFieldInput('zip')"
                    outlined
                    ref="zip"
                    label="0000AA"
                    id="postcode"
                    hide-details
                    :error-messages="errors"
                    solo
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
              <div class="col-12 col-sm-5 pl-3 pl-sm-2">
                <validation-provider
                  v-slot="{ errors }"
                  name="Huisnummer"
                  :rules="{
                    required: true,
                    regex: /(\w?[0-9]+[a-zA-Z0-9\- ]*)/,
                    ErrorResponse: {
                      target: getErrorResponses.house_number,
                    },
                  }"
                >
                  <v-text-field
                    v-model="form.house_number"
                    @input="handleFieldInput('house_number')"
                    outlined
                    :error-messages="errors"
                    ref="house_number"
                    id="house"
                    label="1"
                    solo
                    hide-details
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </v-row>
          </div>

          <div v-else-if="step == 3">
            <v-row>
              <div class="col-12">
                <label for="woning" class="fs--15 font-weight-bold"
                  >Wat voor woning heb je?
                </label>
                <div class="row no-gutters mt-2">
                  <v-col cols="6" class="pr-1">
                    <label class="btn btn-lg p-0 m-0 cursor-pointer"
                      ><input
                        class="btnDiv"
                        v-model="temp_form.woning"
                        value="3781"
                        type="radio"
                        name="woning"
                        required
                      /><span
                        class="btnDiv2 btnDiv3 fs--13 font-weight-bold d-flex flex-column align-center justify-space-between"
                      >
                        <img src="../assets/imgs/house1.png" />
                        Lage woning/huis
                      </span></label
                    >
                  </v-col>
                  <v-col cols="6" class="px-1">
                    <label class="btn btn-lg m-0 cursor-pointer"
                      ><input
                        value="3784"
                        type="radio"
                        name="woning"
                        required
                        v-model="temp_form.woning"
                      /><span
                        class="btnDiv2 btnDiv3 fs--13 font-weight-bold d-flex flex-column align-center justify-space-between"
                      >
                        <img src="../assets/imgs/building.png" />
                        Flat/appartement
                      </span></label
                    >
                  </v-col>
                </div>
              </div>
            </v-row>
          </div>
          <div v-else-if="step == 4">
            <v-row>
              <div class="col-12">
                <label for="roof" class="fs--15 font-weight-bold"
                  >Wat voor soort dak heb je?
                </label>
                <div class="row no-gutters mt-2">
                  <v-col cols="4" class="pr-1">
                    <label
                      class="btn btn-lg pa-0 m-0 w-100 cursor-pointer d-block"
                      ><input
                        class="btnDiv"
                        v-model="temp_form.dak"
                        value="3781"
                        type="radio"
                        name="roof"
                        required
                      /><span
                        class="btnDiv2 fs--13 font-weight-bold d-flex flex-column align-center justify-space-between"
                      >
                        <img src="../assets/imgs/roofing.png" width="55px" />
                        Hellend dak
                      </span></label
                    >
                  </v-col>
                  <v-col cols="4" class="px-1">
                    <label
                      class="btn btn-lg pa-0 w-100 m-0 cursor-pointer d-block"
                      ><input
                        value="3784"
                        type="radio"
                        name="roof"
                        required
                        v-model="temp_form.dak"
                      /><span
                        class="btnDiv2 fs--13 font-weight-bold d-flex flex-column align-center justify-space-between"
                      >
                        <img
                          src="../assets/imgs/garage-door.png"
                          width="55px"
                        />
                        Plat dak
                      </span></label
                    >
                  </v-col>
                  <v-col cols="4" class="pl-1">
                    <label
                      class="btn btn-lg pa-0 m-0 w-100 cursor-pointer d-block"
                      ><input
                        value="3787"
                        type="radio"
                        name="roof"
                        v-model="temp_form.dak"
                        required
                      /><span
                        class="btnDiv2 fs--13 font-weight-bold d-flex flex-column align-center justify-space-between"
                      >
                        <div class="d-flex">
                          <img
                            src="../assets/imgs/roofing.png"
                            class="option-img"
                          />
                          <img
                            src="../assets/imgs/garage-door.png"
                            class="option-img"
                          />
                        </div>
                        Beide
                      </span></label
                    >
                  </v-col>
                </div>
              </div>
            </v-row>
          </div>
          <!-- main form -->

          <div v-else-if="step == 5" class="third_form">
            <div class="mb-4">
              <input
                type="checkbox"
                class="hidden-checkbox"
                v-model="bootCheck"
              />
              <h2 class="form__heading">Jouw dak is geschikt!</h2>
              <h3 class="form__sub_heading">
                Ontvang gratis een berekening van de opbrengsten van je
                zonnepanelen en besparing op je energierekening.
              </h3>
            </div>

            <v-row class="mb-2">
              <div class="col-12 col-sm-4 pr-3 pr-sm-2 d-flex">
                <div class="mr-3 d-flex align-center">
                  <input
                    type="radio"
                    id="dhr"
                    value="male"
                    v-model="form.gender"
                  />
                  <label for="dhr" class="font-weight-bold fs--13 ml-1"
                    >Meneer</label
                  >
                </div>
                <div class="d-flex align-center">
                  <input
                    type="radio"
                    id="mvr"
                    value="female"
                    v-model="form.gender"
                  />
                  <label for="mvr" class="font-weight-bold fs--13 ml-1"
                    >Mevrouw</label
                  >
                </div>
              </div>
            </v-row>
            <v-row class="mt-0">
              <div class="col-6">
                <label for="Voornaam" class="font-weight-bold fs--13"
                  >Voornaam</label
                >
                <validation-provider
                  v-slot="{ errors }"
                  name="Voornaam"
                  :rules="{
                    ...validationRules,
                    ErrorResponse: {
                      target: getErrorResponses.firstname,
                    },
                  }"
                >
                  <v-text-field
                    v-model="form.firstname"
                    @input="handleFieldInput('firstname')"
                    outlined
                    label="Renee"
                    id="Voornaam"
                    ref="firstname"
                    hide-details
                    :error-messages="errors"
                    solo
                    required
                  ></v-text-field>
                </validation-provider>
                <!-- {{ getErrorResponses }} -->
              </div>
              <div class="col-6">
                <label for="house" class="font-weight-bold fs--13"
                  >Achternaam</label
                >

                <validation-provider
                  v-slot="{ errors }"
                  name="Achternaam"
                  :rules="{
                    required: true,
                    regex: /^([a-zA-Z ]){1,30}$/,
                    ErrorResponse: {
                      target: getErrorResponses.lastname,
                    },
                  }"
                >
                  <v-text-field
                    v-model="form.lastname"
                    @input="handleFieldInput('lastname')"
                    outlined
                    ref="lastname"
                    :error-messages="errors"
                    id="Achternaam"
                    label="Blankenburgh"
                    solo
                    hide-details
                    required
                  ></v-text-field>
                </validation-provider>
              </div>
            </v-row>
            <label for="E-mail" class="font-weight-bold fs--13"
              >E-mail adres</label
            ><validation-provider
              v-slot="{ errors }"
              name="E-mail"
              :rules="{
                email: true,
                required: true,
                ErrorResponse: {
                  target: getErrorResponses.email,
                },
              }"
            >
              <v-text-field
                v-model="form.email"
                @input="handleFieldInput('email')"
                outlined
                label="renee@gmail.com"
                id="E-mail"
                ref="email"
                hide-details
                :error-messages="errors"
                solo
                required
              ></v-text-field
            ></validation-provider>
            <label for="Telefoonnummer" class="font-weight-bold fs--13"
              >Telefoonnummer</label
            ><validation-provider
              v-slot="{ errors }"
              name="Telefoonnummer"
              :rules="{
                required: true,
                regex: /^0\d{9}$/,
                ErrorResponse: {
                  target: getErrorResponses.phone_number,
                },
              }"
            >
              <v-text-field
                v-model="form.phone_number"
                @input="handleFieldInput('phone_number')"
                outlined
                label="0612345678"
                id="Telefoonnummer"
                ref="phone_number"
                hide-details
                :error-messages="errors"
                solo
                required
              ></v-text-field
            ></validation-provider>
          </div>

          <!-- buttons -->
          <v-row class="pt-4 button_error">
            <span v-if="showValidationError" class="red--text fs--10">
              Please select at least one</span
            >
            <span v-if="streetError" class="red--text fs--10">
              adres niet gevonden voor u opgegeven postcode en huisnummer</span
            >

            <v-col lg="12" class="mt-">
              <v-btn
                size="lg"
                type="submit"
                color="orange"
                class="w--100 text-normal white--text pt-1"
              >
                <v-progress-circular
                  v-if="getLoader"
                  small
                  indeterminate
                  color="dark"
                  class="my-4"
                ></v-progress-circular>
                <span v-else>
                  <b v-if="step == 0">START WONINGCHECK</b>
                  <v-icon v-if="step == 0" small class="ml-3 mb-1"
                    >mdi-arrow-right-circle</v-icon
                  ><v-icon v-else-if="loader != true" small class="mr-3 mb-1"
                    >mdi-arrow-right-circle</v-icon
                  >
                  <v-progress-circular
                    v-if="loader"
                    small
                    indeterminate
                    color="dark"
                    class="my-4"
                  ></v-progress-circular>
                  <b v-else-if="step == 1 || step == 2 || step == 3">
                    Volgende stap
                  </b>
                  <b v-else-if="step == 4">Controleer mijn gegevens</b>
                  <b v-else-if="step == 5">Bevestigen</b>
                  <!-- <b v-else-if="step == 3">Bevestigen</b> -->
                </span></v-btn
              >
            </v-col></v-row
          >
        </v-form>
        <p class="formText mt-3" v-if="step == 5">
          Alle gegevens zijn vertrouwelijk en worden behandeld in
          overeenstemming met de Europese wetgeving inzake de bescherming van de
          persoonlijke levenssfeer. Lees meer in ons
          <span class="cursor-pointer" @click="privacyModal = !privacyModal">
            privacybeleid
          </span>
          .
        </p>
      </validation-observer>
      <!-- modal -->
      <div class="text-center">
        <v-dialog v-model="dialog" width="360">
          <v-card class="text-center pa-10">
            <h3>Je gegevens worden nu gecontroleerd</h3>
            <v-progress-circular
              indeterminate
              color="dark"
              class="my-4"
            ></v-progress-circular>

            <p class="ma-0" v-if="checkMark1">
              <v-icon color="green" class="mr-2"> mdi-check </v-icon>We zijn
              actief in jouw regio
            </p>
            <p class="ma-0" v-if="checkMark2">
              <v-icon color="green" class="mr-2"> mdi-check </v-icon>Je
              woninggegevens zijn gecheckt
            </p>
            <p class="ma-0" v-if="checkMark3">
              <v-icon color="green" class="mr-2"> mdi-check </v-icon>Je
              berekening is compleet
            </p>
          </v-card>
        </v-dialog>
      </div>
    </div>
    <!-- thankyou -->
    <div v-if="submited">
      <h4
        class="text-decoration- orange--text text-center p-5 form_headin mb-3"
      >
        Bedankt voor uw aanvraag. We nemen zo snel mogelijk telefonisch contact
        met je op om je te informeren over hoe jij het beste kan besparen!
      </h4>
      <img
        v-if="profId !== null"
        referrerpolicy="no-referrer-when-downgrade"
        :src="`https://republish.mijnconsument.nl/m/5681/4ba53c694cda/?event=6635&unique_conversion_id=${profId}`"
        style="width: 1px; height: 1px; border: 0px"
      />
    </div>
    <div v-if="step == 7 || step == 8">
      <img :src="arrowBack" class="arrow_back" @click="goBack(step)" />

      <h4
        class="text-decoration- orange--text text-center p-5 form_headin mb-3"
      >
        Sorry, maar helaas komt jouw woning op dit moment niet in aanmerking
        voor het huren van zonnepanelen.
      </h4>
    </div>

    <!-- privacyModal -->
    <div class="text-center">
      <v-dialog v-model="privacyModal" width="800">
        <v-card class="text-left pa-3">
          <PrivacyModal />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green" text @click="privacyModal = false"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import PrivacyModal from "./PrivacyModal.vue";
import arrowBack from "../assets/arrowBack.svg";

import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
setInteractionMode("eager");
import { mapActions, mapGetters } from "vuex";
import check from "@/assets/check.png";
import Stepper from "./Stepper.vue";

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});
extend("required", {
  ...required,
  message: "verplicht",
});
extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});
extend("regex", {
  ...regex,
  message: "{_value_} : ongeldig formaat",
});
extend("email", {
  ...email,
  message: "Email must be valid",
});
extend("ErrorResponse", {
  params: ["target"],
  validate(value, { target }) {
    console.log("errorrresponse", value, this.getErrorResponses, target);
    return target != null ? target : true;
  },
  message: "{_field_} must be valid",
});

export default {
  name: "FormSection",
  components: {
    ValidationProvider,
    ValidationObserver,
    PrivacyModal,
    Stepper,
  },

  data() {
    return {
      bootCheck: false,
      check,
      arrowBack,
      step: 0,
      dialog: false,
      privacyModal: false,
      viewModal: false,
      submited: false,
      checkMark1: false,
      checkMark2: false,
      checkMark3: false,
      streetError: false,
      loader: false,
      showValidationError: false,
      validationCheck: true,
      // profId: "",
      temp_form: {
        koop: "3277",
        dak: "",
        woning: "",
        thuisbatterij: "",
      },
      form: {
        language: "nl_NL",
        site_custom_name: "mijnconsument_hallostroom",
        site_custom_url: "https://zonnepanelenhuren.mijnconsument.nl",
        publisher_id: "",
        gender: "",
        site_subid: "",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        zip: "",
        house_number: "",
        street: "",
        city: "",
        answers: ["3637"],
      },
    };
  },
  methods: {
    ...mapActions(["postLead", "setPostcode"]),
    handleFieldInput(fieldName) {
      console.log("phone number changed", fieldName);
      if (this.getErrorResponses && !this.getErrorResponses[fieldName]) {
        console.log("phone number changed2", fieldName);
        this.$store.commit("setError", {
          ...this.getErrorResponses,
          [fieldName]: true,
        });
      }
    },
    async onSubmit() {
      if (
        (this.step == 1 && this.temp_form.koop == "3280") ||
        (this.step == 3 && this.temp_form.woning == "3784")
      ) {
        if (this.step == 1) {
          this.step = 7;
        } else {
          this.step = 8;
        }
      } else {
        if (
          this.step == 0 ||
          this.step == 2 ||
          (this.step == 1 && this.temp_form.koop != "") ||
          (this.step == 3 && this.temp_form.woning != "") ||
          (this.step == 4 && this.temp_form.dak != "") ||
          this.step == 5
        ) {
          this.showValidationError = false;
          if (this.step < 5) {
            if (this.step == 2) {
              this.setCity();
            } else {
              this.step++;
              this.$emit("steps");
            }
          } else {
            let resp = await this.postLead({
              ...this.form,
              bootCheck: this.bootCheck,
            });
            if (resp.success == true) {
              this.profileId = resp.profileid;
              this.submited = true;
              this.step++;
              this.$emit("steps");
              window.scrollTo(0, 0);
            } else if (
              this.getErrorResponses.zip == false ||
              this.getErrorResponses.house_number == false
            ) {
              this.step = 2;
              this.$emit("steps");
            }
            const isValid = await this.$refs.myForm.validate();
            for (const key in this.getErrorResponses) {
              if (
                this.getErrorResponses.hasOwnProperty(key) &&
                this.getErrorResponses[key] === false
              ) {
                // firstFalseKey = key;
                console.log("key", key);
                this.$refs[key].focus();
                this.$refs[key].blur();

                break;
              }
            }
          }
        } else {
          this.showValidationError = true;
        }
      }
    },

    async setCity() {
      let postcode = this.form.zip;
      let house = this.form.house_number;
      this.loader = true;
      let resp = await this.setPostcode({ postcode, house });
      this.loader = false;
      if (resp) {
        this.streetError = false;
        this.form.street = resp.street;
        this.form.city = resp.city;
        this.step++;
        this.$emit("steps");
      } else {
        this.streetError = true;
        return;
      }
    },
    goBack(step) {
      if (step == 7) {
        this.step = 1;
      } else if (step == 8) {
        this.step = 3;
      }
    },
  },
  watch: {
    submited(newValue) {
      if (newValue === false) {
        let useScript = document.createElement("script");
        useScript.setAttribute(
          !(function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
              n.callMethod
                ? n.callMethod.apply(n, arguments)
                : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
          })(
            window,
            document,
            "script",
            "https://connect.facebook.net/en_US/fbevents.js"
          ),
          fbq("init", "468081108442050"),
          fbq("track", "PageView")
        );
        document.head.appendChild(useScript);
      } else {
        let useScript = document.createElement("script");
        useScript.setAttribute(
          !(function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
              n.callMethod
                ? n.callMethod.apply(n, arguments)
                : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
          })(
            window,
            document,
            "script",
            "https://connect.facebook.net/en_US/fbevents.js"
          ),
          fbq("init", "468081108442050"),
          fbq("track", "PageView"),
          fbq("track", "Lead")
        );
        document.head.appendChild(useScript);
      }
    },
    step(newValue, oldValue) {
      if (oldValue == 4 && !this.viewModal && this.temp_form.woning != "3784") {
        this.step = 4;
        window.scrollTo(0, 0);
        this.dialog = true;
        this.viewModal = true;
        setTimeout(() => {
          this.checkMark1 = true;
          setTimeout(() => {
            this.checkMark2 = true;
            setTimeout(() => {
              this.checkMark3 = true;
              setTimeout(() => {
                this.step = 5;
                this.dialog = false;
              }, 800);
            }, 1000);
          }, 1000);
        }, 1000);
      }
    },
    dialog(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.$emit("hideRightImg");
      }
    },
  },

  mounted() {
    if (this.submited) {
      let useScript = document.createElement("script");
      useScript.setAttribute(
        !(function (f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = "2.0";
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(
          window,
          document,
          "script",
          "https://connect.facebook.net/en_US/fbevents.js"
        ),
        fbq("init", "468081108442050"),
        fbq("track", "PageView")
      );
      document.head.appendChild(useScript);
    } else {
      let useScript = document.createElement("script");
      useScript.setAttribute(
        !(function (f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = "2.0";
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(
          window,
          document,
          "script",
          "https://connect.facebook.net/en_US/fbevents.js"
        ),
        fbq("init", "468081108442050"),
        fbq("track", "PageView"),
        fbq("track", "Lead")
      );
      document.head.appendChild(useScript);
    }
  },

  created() {
    if (!this.submitted) {
      this.noscriptHtml =
        '<noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=468081108442050&ev=PageView&noscript=1"/></noscript>';
    } else {
      this.noscriptHtml =
        '<noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=468081108442050&ev=PageView&noscript=1"/></noscript>';
    }

    this.form.publisher_id = this.$route.query.site;
    this.form.site_subid = this.$route.query.oa_id;
  },
  computed: {
    ...mapGetters(["getLoader", "getErrorResponses", "profId"]),
    validationRules() {
      const rules = {
        required: true,
        regex: {
          test: (value) => /^[a-zA-Z ]{1,30}$/.test(value),
          message: "Invalid format",
        },
      };

      return rules;
    },
  },
};
</script>

<style lang="scss" scoped>
.hidden-checkbox {
  opacity: 0;
}
.button_error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.arrow_back {
  width: 25px;
  cursor: pointer;
}
.checks {
  display: flex;
  justify-content: start;
  align-items: center;
}
.form__heading {
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 24px;
  color: #4aab3d;
  font-weight: 700;
  font-family: "Rubik";
}
.form__sub_heading {
  font-size: 11px;
  letter-spacing: 0px;
  line-height: 20px;
  font-weight: 500;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
hr {
  width: 17%;
  margin: auto;
  border: 1px solid #ff7e00;
}
.form_heading {
  position: absolute;
  top: 10px;
}
.btnDiv,
.btnDiv2 {
  border: 2px solid #d7dbe8;
  border-radius: 6px;
  padding: 6px 12px;
  display: block;
  text-align: center;
  color: #dbdbdb;
  font-weight: 600;
}
.btnDiv2 {
  padding: 6px !important;
  min-height: 75px;
  color: #000;
  border: 2px solid #dbdbdb;
}
.btnDiv3 {
  min-height: 100px;
}
label.btn {
  //   padding: 0;
}

label.btn input {
  opacity: 0;
  position: absolute;
}

label.btn span {
  //   text-align: center;
  //   color: #dbdbdb;
}

label.btn input:checked + .btnDiv {
  background-color: #4aab3d;
  color: #fff;
  border-color: #4aab3d;
}
label.btn input:checked + .btnDiv2 {
  background-color: transparent !important;
  color: #000;
  border-color: #4aab3d;
}
.col-12 {
  padding: 6px 12px !important;
}
.formText {
  line-height: 1;
  font-size: 9px;
  text-align: justify;
  margin-top: 5px;
}
.selectBoxHeight {
  height: 90px;
}
.option-img {
  width: 40px;
  @media only screen and (max-width: 599.98px) {
    width: 35px;
  }
}
</style>
