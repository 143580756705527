<template>
  <div>
    <h2>Privacy</h2>

    <p>
      Via deze actie worden privacygevoelige gegevens oftewel persoonsgegevens
      verwerkt. Mijn Consument werkt samen met HalloStroom voor deze actie.
      HalloStroom Markt & Klant B.V. acht een zorgvuldige omgang met
      persoonsgegevens van groot belang. Persoonlijke gegevens worden door ons
      dan ook zorgvuldig verwerkt en beveiligd.
    </p>

    <p>
      Bij onze verwerking houden wij ons aan de eisen die de privacywetgeving
      stelt. Dat betekent onder andere dat:
    </p>

    <p>
      &#10004; wij duidelijk vermelden met welke doeleinden wij persoonsgegevens
      verwerken. Dat doen wij via deze privacyverklaring;<br />
      &#10004; wij onze verzameling van persoonsgegevens beperken tot alleen de
      persoonsgegevens die nodig zijn voor legitieme doeleinden;<br />
      &#10004; wij u eerst vragen om uitdrukkelijke toestemming om uw
      persoonsgegevens te verwerken in gevallen waarin uw toestemming is
      vereist;<br />
      &#10004; wij passende beveiligingsmaatregelen nemen om uw persoonsgegevens
      te beschermen en dat ook eisen van partijen die in onze opdracht
      persoonsgegevens verwerken;<br />
      &#10004; wij uw recht respecteren om uw persoonsgegevens op uw aanvraag
      ter inzage te bieden, te corrigeren of te verwijderen.<br />
      &#10004; HalloStroom Markt & Klant B.V. is de verantwoordelijke voor de
      gegevensverwerking. In deze privacyverklaring leggen wij uit welke
      persoonsgegevens wij verzamelen en gebruiken en met welk doel. Wij raden u
      aan deze zorgvuldig te lezen.<br />
      <i
        >Deze privacyverklaring is voor het laatst aangepast op 08-05-2018
        00:00:00.</i
      >
    </p>

    <h3>Gebruik van persoonsgegevens</h3>
    <p>
      Bij het gebruiken van onze webwinkel verkrijgen wij bepaalde gegevens van
      u. Dat kunnen persoonsgegevens zijn. Wij bewaren en gebruiken uitsluitend
      de persoonsgegevens die rechtstreeks door u worden opgegeven, in het kader
      van de door u gevraagde dienst, of waarvan bij opgave duidelijk is dat ze
      aan ons worden verstrekt om te verwerken.
    </p>

    <p>
      Wij gebruiken de volgende gegevens voor de in deze privacyverklaring
      genoemde doelen:
    </p>

    <p>
      &#10004; NAW gegevens &#10004; Telefoonnummer &#10004; Emailadres &#10004;
      Geslacht &#10004; IP-adres
    </p>

    <h3>Registreren</h3>
    <p>
      Bij bepaalde onderdelen van onze webwinkel moet u zich eerst registreren.
      Na registratie bewaren wij via de door u gekozen gebruikersnaam de door u
      opgegeven persoonsgegevens. Wij bewaren deze gegevens zodat u deze niet
      elke keer opnieuw hoeft in te vullen en zodat wij u kunnen contacteren in
      het kader van uitvoering van de overeenkomst.
    </p>

    <p>
      Wij zullen de aan uw gebruikersnaam gekoppelde gegevens niet aan derden
      verstrekken, tenzij dat noodzakelijk is in het kader van de uitvoering van
      de overeenkomst die u met ons sluit of indien dit wettelijk verplicht is.
      In geval van een vermoeden van fraude of misbruik van onze webwinkel
      kunnen wij persoonsgegevens aan de bevoegde autoriteiten overhandigen.
    </p>

    <h3>Afhandelen bestelling</h3>
    <p>
      Wanneer u bij ons een bestelling plaatst, maken wij voor de afhandeling
      daarvan gebruik van uw persoonsgegevens. Indien dat nodig is voor een
      goede afhandeling kunnen wij uw persoonsgegevens ook aan derden
      verstrekken. Meer daarover leest u verderop in deze privacyverklaring.
    </p>

    <h3>Publicatie</h3>
    <p>Wij publiceren uw klantgegevens niet.</p>

    <h3>Verstrekking aan derden</h3>
    <p>
      Wij kunnen uw gegevens doorgeven aan onze partners. Deze partners zijn
      betrokken bij de uitvoering van de overeenkomst. Deze partners zijn (mede)
      gevestigd buiten de EU.
    </p>

    <h3>Cookies</h3>
    <p>
      Op onze webwinkel wordt gebruik gemaakt van cookies. Ook via derden die
      door ons zijn ingeschakeld worden cookies geplaatst.
    </p>

    <p>
      Het staat u vrij om cookies uit te schakelen middels uw browser. Houd er
      wel rekening mee dat het mogelijk is dat onze website dan niet meer
      optimaal werkt.
    </p>

    <p>
      Met derden die cookies plaatsen hebben wij afspraken gemaakt over het
      gebruik van de cookies en applicaties. Toch hebben wij geen volledige
      controle op wat de aanbieders van deze applicaties zelf met de cookies
      doen wanneer zij deze uitlezen. Voor meer informatie over deze applicaties
      en hoe zij met cookies omgaan, zie graag de privacyverklaringen van deze
      partijen (let op: deze kunnen regelmatig wijzigen).
    </p>

    <h3>Beveiliging</h3>
    <p>
      Wij nemen beveiligingsmaatregelen om misbruik van en ongeautoriseerde
      toegang tot persoonsgegevens te beperken.
    </p>

    <h3>Bewaartermijnen</h3>
    <p>
      De hierboven beschreven persoonsgegevens worden bewaard voor zo lang als
      nodig om uw bestellingen af te handelen, inclusief garantie. Daarna
      bewaren wij gegevens nog maximaal een jaar voor de beschreven statistische
      doeleinden. De gegevens worden vervolgens gewist, tenzij er een wettelijke
      plicht is die langer bewaren vereist (zoals de fiscale bewaarplicht van
      zeven jaar voor betaalgegevens).
    </p>

    <h3>Websites van derden</h3>
    <p>
      Deze privacyverklaring is niet van toepassing op websites van derden die
      door middel van links met onze webwinkel zijn verbonden. Wij kunnen niet
      garanderen dat deze derden op een betrouwbare of veilige manier met uw
      persoonsgegevens omgaan. Wij raden u aan de privacyverklaring van deze
      websites te lezen alvorens van deze websites gebruik te maken.
    </p>

    <h3>Wijzigingen in deze privacyverklaring</h3>
    <p>
      Wij behouden ons het recht voor om wijzigingen aan te brengen in deze
      privacyverklaring. Het verdient aanbeveling om deze privacyverklaring
      geregeld te raadplegen, zodat u van deze wijzigingen op de hoogte bent.
    </p>

    <h3>Inzage en wijzigen van uw gegevens</h3>
    <p>
      Voor vragen over ons privacybeleid of vragen omtrent inzage en wijzigingen
      in (of verwijdering van) uw persoonsgegevens kunt u te allen tijde contact
      met ons opnemen via onderstaande gegevens.
    </p>

    <p>
      U kunt ons ook een verzoek toesturen om deze gegevens in te zien, te
      wijzigen of te verwijderen. Ook kunt u een verzoek indienen om een
      gegevensexport op te vragen voor gegevens die wij met uw toestemming
      gebruiken, of gemotiveerd aangeven dat u de verwerking van
      persoonsgegevens door ons wil laten beperken.
    </p>

    <p>
      Om misbruik te voorkomen kunnen wij u daarbij vragen om u adequaat te
      identificeren. Wanneer het gaat om inzage in persoonsgegevens gekoppeld
      aan een cookie, dient u een kopie van het cookie in kwestie mee te sturen.
      U kunt deze terug vinden in de instellingen van uw browser. Indien de
      gegevens niet kloppen, kunt u ons verzoeken om de gegevens te wijzigen of
      te laten verwijderen.
    </p>

    <h3>Autoriteit Persoonsgegevens</h3>
    <p>
      Natuurlijk helpen wij u graag verder als u klachten heeft over de
      verwerking van uw persoonsgegevens. Op grond van de privacywetgeving heeft
      u ook het recht om een klacht in te dienen bij de Autoriteit
      Persoonsgegevens tegen deze verwerkingen van persoonsgegevens. U kunt
      hiervoor contact opnemen met de Autoriteit Persoonsgegevens.
    </p>

    <h3>Contactgegevens</h3>
    <p>
      HalloStroom Markt & Klant B.V.<br />
      Bouwmeesterplein 1<br />
      2801BX Gouda<br />
      klantenservice@hallostroom.nl<br />
      0307670078
    </p>
  </div>
</template>
<script>
export default {
  name: "PrivacyModal",
};
</script>
