<template>
  <v-container class="container--lg-fluid">
    <div class="extra-padding py-10 text-center">
      <p class="fs--14 mb-0">
        Mijn Consument helpt je met het uitzoeken van zonnepanelen.
      </p>
      <h2 class="fs--34">Waarom zonnepanelen huren?</h2>
      <v-row class="my-5">
        <v-col cols="12" sm="4">
          <div class="rounded shadow-sm text-left px-5 py-3 h-100">
            <img src="../assets/imgs/house.png" width="50px" />
            <h6 class="fs--16">Bespaar direct</h6>
            <small class="gray--text"
              >Vanaf dag 1 bespaar je op je energierekening, want je wekt je
              eigen stroom op. Zo houd jij geld over voor andere belangrijke
              dingen.</small
            >
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="rounded shadow-sm text-left px-5 py-3 h-100">
            <img src="../assets/imgs/slim.png" width="50px" />
            <h6 class="fs--16">Hele jaar rendement</h6>
            <small class="gray--text"
              >Je huurt je zonnepanelen voor een vast bedrag per maand. Je hoeft
              geen spaargeld te hebben of een lening af te sluiten.</small
            >
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div class="rounded shadow-sm text-left px-5 py-3 h-100">
            <img src="../assets/imgs/power.png" width="50px" />
            <h6 class="fs--16">Geen gedoe</h6>
            <small class="gray--text"
              >Je zonnepanelen doen het altijd. Is er toch iets mis? Geen
              zorgen: wij fixen het voor je. Natuurlijk zonder extra
              kosten.</small
            >
          </div>
        </v-col>
      </v-row>
      <v-btn
        size="lg"
        @click="scrollToTop"
        color="orange"
        class="fs--14 text-normal white--text"
        ><v-icon small class="mr-3">mdi-arrow-right-circle</v-icon
        ><b> Start berekening </b></v-btn
      >
    </div>
  </v-container>
</template>
<script>
export default {
  name: "Waroom",
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.h-100 {
  height: 100%;
}
</style>
