<template>
  <div class="pt-sm-15 pt-10 mt-sm-15 mt-10 text-center">
    <!-- mt-5 pt-4 -->
    <p class="fs--12 font-weight-bold">
      Alle gegevens zijn vertrouwelijk en worden behandeld in overeenstemming
      met de Europese wetgeving<br class="d-none d-sm-block" />
      inzake de bescherming van de persoonlijke levenssfeer. Lees meer in ons
      <span
        class="cursor-pointer text-decoration-underline"
        @click="dialog = !dialog"
      >
        privacybeleid</span
      >.
    </p>
    <!-- modal -->
    <div class="text-center">
      <v-dialog v-model="dialog" width="800">
        <v-card class="text-left pa-3">
          <PrivacyModal />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green" text @click="dialog = false"> OK </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import PrivacyModal from "./PrivacyModal.vue";
export default {
  name: "Footer",
  components: {
    PrivacyModal,
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
