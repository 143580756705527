<template>
  <v-container fluid class="light-blue lighten-2 pb-0">
    <div class="pictures-padding pt-10 text-center">
      <div class="white--text">
        <h2 class="fs--40" style="line-height: 1.2">
          Is jouw woning geschikt:<br />
          Hoe werkt het?
        </h2>
        <p class="fs--14 mb-16">
          De huidige elektriciteitsprijzen swingen de pan uit. Wie de grote
          energieleveranciers te slim af wil zijn,
          <br class="d-none d-sm-block" />
          produceert zijn eigen energie. Ontdek hoe jij honderden euro's per
          maand bespaart!
        </p>
      </div>
      <v-row
        class="my-5 rounded white section_width mx-auto position-relative"
        no-gutters
      >
        <v-col cols="12" sm="4" class="position-relative">
          <div
            class="rounded text-left pa-6 pa-sm-4 pa-md-6 h-100"
            @click="selected = 1"
          >
            <img src="../assets/imgs/solar.png" width="50px" height="51px" />
            <h6 class="fs--16">Analyse van je dak</h6>
            <small class="gray--text"
              >Start de berekening via ons formulier. Vertel ons over je
              situatie en ontvang een gratis en vrijblijvend persoonlijk advies
              van onze specialisten.</small
            >
            <v-btn
              @click="scrollToTop"
              color="orange"
              class="btn_font w--100 text-normal white--text mt-16 mb-3"
              v-if="selected == 1"
              ><v-icon small class="mr-2">mdi-arrow-right-circle</v-icon
              ><b>Start woningcheck</b></v-btn
            >
          </div>
          <hr class="hr_tab" v-if="selected == 1" />
        </v-col>
        <v-col cols="12" sm="4" class="border-LR position-relative">
          <div
            class="rounded text-left pa-6 pa-sm-4 pa-md-6 h-100"
            @click="selected = 2"
          >
            <img src="../assets/imgs/energy.png" width="50px" height="51px" />
            <h6 class="fs--16">Installatie binnen 1 dag</h6>
            <small class="gray--text"
              >Een van onze erkende installateurs uit jouw regio plaatst je
              panelen. Dit alles gebeurt supersnel. We laten zien hoe je panelen
              werken en laten je huis netjes achter. Het mooie is dat jouw
              zonnepanelen meteen energie opwekken. Je bespaart dus
              direct.</small
            >

            <v-btn
              @click="scrollToTop"
              color="orange"
              class="btn_font w--100 text-normal white--text mt-5 mb-3"
              v-if="selected == 2"
              ><v-icon small class="mr-2">mdi-arrow-right-circle</v-icon
              ><b> Start woningcheck </b></v-btn
            >
          </div>
          <hr class="hr_tab" v-if="selected == 2" />
        </v-col>
        <v-col cols="12" sm="4" class="position-relative">
          <div
            class="rounded text-left pa-6 pa-sm-4 pa-md-6 h-100"
            @click="selected = 3"
          >
            <img src="../assets/imgs/wallet.png" width="50px" height="51px" />
            <h6 class="fs--16">Zorgeloos genieten</h6>
            <small class="gray--text"
              >Je bent klaar voor een duurzame toekomst. Met onze app zie je op
              elk moment van de dag hoeveel energie je zonnepanelen opwekken. Je
              bent verzekerd, dus als er iets is, wij lossen het voor je
              op.</small
            >
            <v-btn
              @click="scrollToTop"
              color="orange"
              class="btn_font w--100 text-normal white--text mt-16 mb-3"
              v-if="selected == 3"
              ><v-icon small class="mr-2">mdi-arrow-right-circle</v-icon
              ><b> Start woningcheck </b></v-btn
            >
          </div>
          <hr class="hr_tab" v-if="selected == 3" />
        </v-col>
        <hr class="hr_tabs" />
      </v-row>
    </div>
    <div class="bg_bottom py-0 px-sm-2 px-0">
      <img src="../assets/imgs/bottomBG.png" width="100%" />
    </div>
  </v-container>
</template>
<script>
export default {
  name: "Geschikt",
  data() {
    return { selected: 1 };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.h-100 {
  height: 100%;
}

.btn_font {
  font-size: 12px !important;
  @media (min-width: 599.98px) and (max-width: 750px) {
    font-size: 9px !important;
  }
}
.border-LR {
  border: thin solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
  border-top: none;
}
.hr_tabs {
  border-bottom: 3px solid #eaeaea;
  position: absolute;
  width: 100%;
  bottom: -3px;
  z-index: 9;
}
.hr_tab {
  border-bottom: 3px solid #4aab3d;
  position: absolute;
  width: 100%;
  z-index: 10;
  transition: 3s;
  animation: scale infinite linear 5s;
  animation-delay: 5.1s;
}
.bg_bottom {
  height: 0px;
  img {
    margin-top: -250px;
    z-index: 0;
    border-radius: 20px;
    @media (max-width: 959.98px) {
      margin-top: -130px;
    }

    @media (max-width: 599.98px) {
      margin-top: -90px;
    }
    @media (min-width: 1400.98px) {
      height: 350px;
    }
  }
}
</style>
