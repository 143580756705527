<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
<style lang="scss">
html {
  scroll-behavior: smooth;
}
// @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700;900&display=swap");
* {
  font-family: "Rubik", sans-serif;
}
.theme--light.v-application {
  background-color: #fcfcfc !important;
}
@for $i from 5 through 96 {
  .fs--#{$i} {
    font-size: $i + px;
    @media (max-width: 959.98px) {
      font-size: $i/2 + 9 + px;
    }
    @media (max-width: 599.98px) {
      font-size: $i/2 + 5 + px;
    }
  }
}

$weights: 100 200 300 400 500 600 700 800;
@each $weight in $weights {
  .fw--#{$weight} {
    font-weight: $weight;
  }
}

@for $i from 5 through 100 {
  .w--#{$i} {
    width: ($i)+0%;
  }
}
.extra-padding {
  padding: 0 10%;
  @media only screen and (max-width: 991.98px) {
    padding: 0 7.4%;
  }
  @media only screen and (max-width: 599.98px) {
    padding: 0 3%;
  }
}
.pictures-padding {
  padding-right: 22%;
  padding-left: 22%;
  @media only screen and (max-width: 991.98px) {
    padding: 0 7.4%;
  }
  @media only screen and (max-width: 599.98px) {
    padding: 0 3%;
  }
}
.position-relative {
  position: relative;
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot,
.v-btn--is-elevated {
  box-shadow: none !important;
}

.theme--light.v-label {
  font-weight: bold !important;
  color: #dbdbdb !important;
}
@media (max-width: 1263.98px) {
  .container--lg-fluid {
    max-width: 100%;
    width: 100%;
    min-width: 100%;
  }
}
.text-normal {
  text-transform: none !important;
}
.shadow-sm {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1) !important;
}
.cursor-pointer {
  cursor: pointer;
}

.third_form {
  .v-text-field.v-text-field--solo .v-input__control {
    min-height: 35px;
  }
  .v-text-field--filled > .v-input__control > .v-input__slot,
  .v-text-field--full-width > .v-input__control > .v-input__slot,
  .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 38px !important;
  }
}
</style>
