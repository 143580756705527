<template>
  <div class="d-flex flex-column mb-4">
    <b style="text-align: center" class="mb-2">Voortgang woningcheck:</b>
    <div class="custom-stepper">
      <div
        v-for="step in steps"
        :key="step"
        :class="['custom-stepper-step', { active: step < currentStep }]"
      >
        <div class="step-icon">
          <div class="innnerdiv" :class="{ hidden: currentStep > step }"></div>
          <div class="checkDiv" :class="{ visible: currentStep > step }">
            <img :src="check" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import check from "@/assets/check-white.png";
export default {
  data() {
    return {
      steps: [1, 2, 3, 4],
      currentStep: this.defaultstep,
      check,
    };
  },
  props: {
    defaultstep: {
      type: Number,
    },
  },
  methods: {
    getStepLabel(step) {
      return `Step ${step}`;
    },
  },
  watch: {
    defaultstep() {
      this.currentStep = this.defaultstep;
    },
  },
  created() {
    this.currentStep = this.defaultstep;
  },
};
</script>

<style lang="scss">
.custom-stepper {
  display: flex;
  justify-content: space-evenly;
  margin: 0.5rem 3rem;
}

.custom-stepper-step {
  display: flex;
  align-items: center;
}
.innnerdiv {
  background-color: #32ba7c;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  &.hidden {
    display: none;
  }
}
.checkDiv {
  display: none;
  &.visible {
    display: flex;
  }
}

.custom-stepper-step.active .step-icon {
  background-color: #32ba7c;
  color: white;
}
.custom-stepper-step.active .innnerdiv {
  display: none;
}
.custom-stepper-step.active .checkDiv {
  display: flex;
  height: 20px;
  width: 20px;
}

.step-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  border: 1px solid #32ba7c;
}
</style>
