<template>
  <v-container class="text-center container--lg-fluid text-sm-left">
    <div class="header pt-2">
      <div class="extra-padding">
        <!-- <img :src="logo" width="200px" /> -->
        <a href="/"> <img :src="logo" width="200px" /></a>
      </div>
      <b>Zonnepanelen zonder zorgen</b>
    </div>
  </v-container>
</template>
<script>
import logo from "@/assets/Logo1.png";
export default {
  name: "Header",
  data() {
    return {
      logo,
    };
  },
};
</script>

<style lang="scss" scoped>
a {
  cursor: pointer;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
