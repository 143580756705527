<template>
  <div :class="['hero', step == 5 ? 'h-auto' : 'hero500']">
    <v-container class="container--lg-fluid">
      <v-row class="extra-padding d-flex align-center forms_height">
        <v-col cols="12" md="6" class="white--text section_col h-100">
          <div class="mr-md-10 mr-lg-15 form_height">
            <h2
              class="fs--20 fw--700 py-xl-3 py-2 text-center"
              style="line-height: 1 letter-spacing:1px"
            >
              <b class="fw--700">Zonnepanelen zonder investering:</b>
              <br />
              direct besparen!
            </h2>
            <!-- <h2 v-if="step >= 3" class="fs--25" style="line-height: 1.2">
              GEFELICITEERD! Je komt in aanmerking voor de zonnepanelen. Naar
              wie kunnen wij de berekening sturen?
            </h2> -->
            <v-card class="bg-white mt-3 px-10 pb-10 pt-5 position-relative">
              <FormSection @steps="step++" @hideRightImg="z_index = false" />
            </v-card>
          </div>
          <img src="../assets/imgs/yellowBadge.png" class="yellowBadge" />
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center justify-md-end mt-auto"
        >
          <img
            src="../assets/imgs/hero_right.png"
            width="75%"
            :class="z_index ? 'z-index999' : ''"
            class="header__right--img shadow"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FormSection from "./FormSection.vue";
export default {
  name: "Hero",
  components: { FormSection },
  data() {
    return {
      step: 0,
      z_index: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.hero500 {
  height: 500px;
  @media only screen and (max-width: 959.98px) {
    height: 540px;
  }
  @media only screen and (max-width: 959.98px) {
    height: auto;
  }
  .forms_height {
    height: 498px;
    @media only screen and (max-width: 959.98px) {
      height: auto;
    }
  }
}
.hero {
  background-image: url(../assets/imgs/header_bg.png);
  background-size: cover;
  background-position: center;
  position: relative;
  @media only screen and (max-width: 599.98px) {
    background-position-x: 39%;
  }
  .section_col {
    height: 100%;
    position: relative;
    // .form_height {
    //   height: 36.45rem;
    //   @media only screen and (max-width: 767.98px) {
    //     height: 80%;
    //   }
    // }
    .yellowBadge {
      position: absolute;
      right: -70px;
      top: 45px;
      width: 130px;
      z-index: 10;
      @media only screen and (max-width: 959.98px) {
        display: none;
        // right: -5px;
        // bottom: 430px;
        // width: 28%;
      }
    }
  }
  .header__right--img {
    position: absolute;
    // width: 460px;
    // bottom: -100px;
    width: 21%;
    top: 175%;
    right: 30px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

    @media only screen and (max-width: 1449.98px) {
      // bottom: -80px;
      width: 21%;
      top: 175%;
    }
    @media only screen and (max-width: 1349.98px) {
      // bottom: -50px;
      // right: 15px;
      width: 21%;
      top: 179%;
    }
    @media only screen and (max-width: 959.98px) {
      margin-right: 0px !important;
      position: inherit !important;
      width: 460px;
    }
    @media only screen and (max-width: 549.98px) {
      width: 250px;
    }
  }
  .z-index999 {
    z-index: 99999 !important;
    @media only screen and (max-width: 991.98px) {
      z-index: 9 !important;
    }
  }
}
</style>
